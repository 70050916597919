<template>
  <v-container fluid>
    <div>
      <listing-header
        title="Agendamento de Produção"
        :isManutencao="true"
        :relatorioBtn="true"
        @clickAgendar="agendarManutencao"
        @clickAgendarVarios="agendarVariosManutencao"
        @filter="filter"
        :filtrarAllEnabled="true"
        @filterAll="filterAll"
        :agendarBtn="true"
        :agendarEnabled="true"
        :isButtonLoading="isButtonLoading"
      />
      <listing-header-manutencao
        :selectAll="selectAll"
        :checkedOrdersLength="agendados.length"
        :shownOrdersLength="pedidos.length"
        :blockedOrdersLength="blockedOrdersLength"
      />
      <template v-if="!pedidosManutencaoService.loading">
        <div
          v-for="(pedido, index) in pedidos"
          :key="index"
        >
          <div>
            <listing-card-manutencao :pedido="pedido" />
          </div>
        </div>
        <v-col>
          <default-pagination
            v-model="pagination.currentPage"
            :length="pagination.lastPage"
            :disabled="pedidos.length === 0"
            @input="paginate(pagination.currentPage)"
          />
        </v-col>
      </template>
      <v-col v-else>
        <default-spinner />
      </v-col>
    </div>
    <modal-agendar-manutencao @sendAgendamento="sendAgendamento" />
    <modal-agendar-varios-manutencao
      @sendAgendamentoVarios="sendAgendamentoVarios"
    />
    <modal-alert-manutencao :modalAlert="modalAlert" />
    <modal-alert-pedidos-nao-reagendados
      :ordersLength="ordersLength"
      :pedidosNaoReagendados="pedidosNaoReagendados"
    />
    <modal-alert-pedidos-inadequados :pedidosInadequados="pedidosInadequados" />
  </v-container>
</template>

<script>
import DefaultPagination from "../../../shared/components/vuetify/DefaultPagination.vue";
import DefaultSpinner from "../../../shared/components/DefaultSpinner.vue";
import ListingHeader from "../listagem_pedidos/ListingHeader.vue";
import ListingCardManutencao from "./ListingCardManutencao.vue";
import ListingHeaderManutencao from "./ListingHeaderManutencao.vue";
import ModalAgendarManutencao from "./ModalAgendarManutencao";
import PedidosManutencaoService from "../../../services/PedidosManutencaoService";
import ModalAlertManutencao from "./ModalAlertManutencao.vue";
import ModalAgendarVariosManutencao from "./ModalAgendarVariosManutencao.vue";
import ModalAlertPedidosNaoReagendados from "./ModalAlertPedidosNaoReagendados.vue";
import ModalAlertPedidosInadequados from "./ModalAlertPedidosInadequados.vue";
export default {
  name: "Manutencao",
  components: {
    DefaultPagination,
    DefaultSpinner,
    ListingHeader,
    ListingHeaderManutencao,
    ListingCardManutencao,
    ModalAgendarManutencao,
    ModalAlertManutencao,
    ModalAgendarVariosManutencao,
    ModalAlertPedidosNaoReagendados,
    ModalAlertPedidosInadequados,
  },
  data: () => ({
    pedidosManutencaoService: PedidosManutencaoService.build(),
    isButtonLoading: false,
    pedidos: [],
    orders: [],
    ordersLength: "",
    pedidosAgil: false,
    pedidosWeb: true,
    pagination: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      lastPage: 0,
      previousPage: 0,
      nextPage: 0,
    },
    modalAlert: {
      message: "",
      variant: "",
    },
    filtro: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      lastPage: 0,
      previousPage: 0,
      nextPage: 0,
    },
    modalAgendar: {
      show: false,
    },
    totalRegistros: 0,
    pedidosNaoReagendados: [],
    pedidosInadequados: [],
    reRender: 0,
  }),
  created() {
    this.pedidosManutencaoService =
      PedidosManutencaoService.build().setVm(this);
  },
  async mounted() {
    await this.pedidosManutencaoService.getPedidos(
      this.pagination.currentPage,
      this.filtro,
      this.pedidosAgil,
      this.pedidosWeb
    );
  },
  methods: {
    selectAll() {
      this.pedidosManutencaoService.selectAll();
    },
    agendarManutencao() {
      this.pedidosManutencaoService.agendarManutencao();
    },
    agendarVariosManutencao() {
      this.pedidosManutencaoService.agendarVariosManutencao();
    },
    async paginate(page) {
      await this.pedidosManutencaoService.getPedidos(
        page,
        this.filtro,
        this.pedidosAgil,
        this.pedidosWeb
      );
    },
    async filter(params) {
      this.isButtonLoading = true;
      this.filtro = { ...params };
      await this.pedidosManutencaoService.getPedidos(
        1,
        this.filtro,
        this.pedidosAgil,
        this.pedidosWeb
      );
      this.isButtonLoading = false;
    },
    async filterAll(params) {
      (this.isButtonLoading = true),
        (this.filtro = { ...params, perPage: this.totalRegistros });
      await this.pedidosManutencaoService.getPedidos(
        1,
        this.filtro,
        this.pedidosAgil,
        this.pedidosWeb
      );
      this.isButtonLoading = false;
    },
    sendAgendamento({ date, time }) {
      this.pedidosManutencaoService.setDataAgendamento(`${date} ${time}:00`);
      this.pedidosManutencaoService.sendAgendamento();
    },
    sendAgendamentoVarios({ orders, date, time }) {
      this.pedidosManutencaoService.setDataAgendamento(`${date} ${time}:00`);
      this.pedidosManutencaoService.sendAgendamentoVarios(orders);
    },
  },
  computed: {
    agendados() {
      return this.pedidos.filter(
        (value) => value.agendado && value.bloqueioStatus !== "BLOQUEADO"
      );
    },
    blockedOrdersLength() {
      return this.pedidos.filter(
        (value) => value.bloqueioStatus === "BLOQUEADO"
      ).length;
    },
  },
};
</script>

<style scoped></style>
