<template>
  <b-modal data-bv
    id="modal-agendar"
    body-class="rounded"
    hide-footer
    hide-header
    centered
    @hidden="onClosingModal"
  >
    <div class="mt-2">
      <div class="d-block text-center">
        <h4 class="font-weight-bold">Data Agendamento</h4>
      </div>
      <div class="col-8 mx-auto">
        <b-form>
          <b-form-group
            id="input-group-1"
            label="Data para Produção"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.date"
              type="date"
              placeholder="Data para Produção"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Hora para Produção:"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              type="time"
              v-model="form.time"
              placeholder="Hora para Produção"
              required
            ></b-form-input>
          </b-form-group>
          <b-button @click="sendAgendamento" style="background: #120947"
            >Enviar</b-button
          >
        </b-form>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalAgendarManutencao",
  data: () => ({
    form: {
      date: "",
      time: "",
    },
  }),
  methods: {
    sendAgendamento() {
      this.$emit("sendAgendamento", {
        ...this.form,
      });
    },
    onClosingModal() {
      Object.keys(this.form).forEach((key) => (this.form[key] = ""));
    },
  },
};
</script>

<style scoped></style>
