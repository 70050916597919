<template>
  <b-modal data-bv
    hide-footer
    centered
    content-class="modal-body"
    id="modal-alert-pedidos-inadequados"
  >
    <div>
      <div>
        <b-card
          style="box-shadow: none"
          no-body
          class="w-75 mx-auto mb-0 bg-transparent text-center text-danger border-0"
        >
          <span class="text-danger h6 font-weight-bold"
            >Revise a grafia dos pedidos abaixo</span
          >
        </b-card>
        <b-card class="mt-0 mb-2 mt-2 card mx-auto" style="width: 20rem">
          <b-table
            hover
            :fields="fields"
            sticky-header="70vh"
            :items="pedidosInadequados"
            sortable
            sort-by="motivo"
            bordered
            no-border-collapse
            head-variant="light"
          >
          </b-table>
        </b-card>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalAlertPedidosInadequados",
  data: () => ({
    fields: [
      {
        key: "pedido",
        label: "Pedido inadequado",
        sortable: true,
      },
    ],
  }),
  props: {
    pedidosInadequados: { Type: Array, required: true },
  },

  methods: {},
};
</script>

<style scoped>
.alert-danger {
  background-color: #f8d7da !important;
}
.alert-success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}

::v-deep .modal-body {
  background-color: #dadfe0;
}

::v-deep .card {
  border-radius: 10px;
  box-shadow: 5px 5px 5px -3px rgb(0 0 0 / 15%);
}

::v-deep .modal-header {
  padding: 0;
}

.b-table-sticky-header {
  margin-bottom: 0;
}
</style>
