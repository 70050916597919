<template>
  <default-table-body-row>
    <v-col
      cols="1"
      class="d-flex justify-center"
    >
      <default-checkbox
        v-model="pedido.agendado"
        v-if="pedido.bloqueioStatus !== 'BLOQUEADO'"
      ></default-checkbox>
    </v-col>
    <v-col
      cols="10"
      md="3"
    >
      <span class="d-block d-md-none font-weight-bold">Pedido</span>
      <span
        class="d-block text-wrap font-weight-bold secondary--text mb-n2"
        :title="pedido.numeroPedido"
      >
        {{ pedido.numeroPedido }}
      </span>
      <span
        class="text-overline font-weight-light text--text"
        :title="pedido.dataProducao | dateDMY"
      >
        {{ pedido.dataProducao | dateDMY }}
      </span>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <span class="d-block d-md-none font-weight-bold">Cliente</span>
      <span
        class="d-block text-wrap mb-n1"
        :title="pedido.clienteNome"
      >
        {{ pedido.clienteNome }}
      </span>
      <span
        class="text-wrap font-weight-light secondary--text"
        :title="pedido.kitDescricao"
      >
        {{ pedido.kitDescricao }}
      </span>
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <span class="d-block d-md-none font-weight-bold">Transportadora</span>
      <span :title="pedido.transportadoraNome">{{
        pedido.transportadoraNome
      }}</span>
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <span class="d-block d-md-none font-weight-bold">Status</span>
      <block-status :block-status="pedido.bloqueioStatus" />
    </v-col>
  </default-table-body-row>
</template>

<script>
import DefaultCheckbox from "../../../shared/components/vuetify/DefaultCheckbox.vue";
import DefaultTableBodyRow from "../../../shared/components/vuetify/DefaultTableBodyRow.vue";
import BlockStatus from "@/shared/components/vuetify/BlockStatus.vue";
export default {
  name: "ListingCardManutencao",
  components: {
    DefaultCheckbox,
    BlockStatus,
    DefaultTableBodyRow,
  },
  props: {
    pedido: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>