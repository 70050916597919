<template>
  <b-modal data-bv
    id="modal-agendar-varios-manutencao"
    body-class="rounded"
    hide-footer
    hide-header
    centered
    @hidden="onClosingModal"
  >
    <div class="mt-2">
      <div class="d-block text-center">
        <h4 class="font-weight-bold">Agendamento +</h4>
      </div>
      <div class="col-8 mx-auto mt-3">
        <b-form class="d-flex flex-column">
          <b-form-group
            id="input-group-1"
            label="Números dos pedidos"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="form.orders"
              type="text"
              placeholder="Ex.: 1111, 2222, 3333"
              required
              class="m-0"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Data para produção"
            label-for="input-20"
          >
            <b-form-datepicker
              id="input-2"
              v-model="form.date"
              type="date"
              placeholder="Selecione a data"
              required
              locale="pt"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              label-no-date-selected="Selecione a data"
              today-button
              reset-button
              close-button
              label-today-button="Hoje"
              label-reset-button="Apagar"
              label-close-button="Fechar"
              no-close-on-select
              label-current-month="Mês atual"
              label-next-month="Próximo mês"
              label-prev-month="Mês anterior"
              label-next-year="Próximo ano"
              label-prev-year="Ano anterior"
              label-help=""
            ></b-form-datepicker>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="Hora para Produção:"
            label-for="input-3"
          >
            <b-form-input
              id="input-3"
              type="time"
              v-model="form.time"
              placeholder="Hora para Produção"
              required
            ></b-form-input>
          </b-form-group>
          <b-button
            class="mt-2"
            @click="sendAgendamentoVarios"
            style="background: #120947"
            >Enviar</b-button
          >
        </b-form>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalAgendarVariosManutencao",
  data: () => ({
    form: {
      orders: "",
      date: "",
      time: "",
    },
  }),
  methods: {
    sendAgendamentoVarios() {
      this.$emit("sendAgendamentoVarios", {
        ...this.form,
      });
    },
    onClosingModal() {
      Object.keys(this.form).forEach((key) => (this.form[key] = ""));
    },
  },
};
</script>

<style scoped>
.form-control:hover {
  border: black 1px solid;
}
</style>
