<template>
  <b-modal data-bv
    hide-footer
    centered
    content-class="modal-body"
    id="modal-alert-pedidos-nao-reagendados"
  >
    <div>
      <div class="d-flex justify-content-center">
        <span class="">
          <b-badge class="p-2" variant="light"
            ><span class="h4 font-weight-bold text-primary">{{
              ordersLength
            }}</span>
            <p
              style="font-size: 11px; font-weight: 400"
              class="ml-1 mb-1 text-uppercase"
            >
              {{ ordersLength > 1 ? "analisados" : "analisado" }}
            </p></b-badge
          ><span></span>
        </span>
        <span class="ml-3">
          <b-badge variant="light" class="p-2"
            ><span class="h4 font-weight-bold text-success">{{
              NumeroDePedidosReagendados
            }}</span
            ><span class="font-weight-light">/{{ ordersLength }}</span>
            <p
              style="font-size: 11px; font-weight: 400"
              class="ml-1 mb-1 text-uppercase"
            >
              {{
                NumeroDePedidosReagendados > 1 ? "reagendados" : "reagendado"
              }}
            </p></b-badge
          ><span></span>
        </span>
        <span class="ml-3">
          <b-badge class="p-2" variant="light"
            ><span class="h4 font-weight-bold text-danger">{{
              pedidosNaoReagendados.length
            }}</span
            ><span class="font-weight-light">/{{ ordersLength }}</span>
            <p
              style="font-size: 11px; font-weight: 400"
              class="ml-1 mb-1 text-uppercase"
            >
              {{ pedidosNaoReagendados.length > 1 ? "recusados" : "recusado" }}
            </p></b-badge
          ><span></span>
        </span>
      </div>
      <div>
        <b-card class="mt-3 mb-2 card">
          <b-table
            hover
            :fields="fields"
            sticky-header="70vh"
            :items="pedidosNaoReagendados"
            caption-top
            sortable
            sort-by="motivo"
            bordered
            no-border-collapse
            head-variant="light"
          >
          </b-table>
        </b-card>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalAlertPedidosNaoReagendados",
  data: () => ({
    fields: [
      {
        key: "pedido",
        sortable: true,
        label: "Pedido recusado",
      },
      {
        key: "motivo",
        sortable: true,
        label: "Motivo",
      },
    ],
  }),
  props: {
    pedidosNaoReagendados: { Type: Array, required: true },
    ordersLength: { Type: Number, required: true },
  },
  methods: {},
  computed: {
    NumeroDePedidosReagendados() {
      return this.ordersLength - this.pedidosNaoReagendados.length;
    },
  },
};
</script>

<style scoped>
::v-deep .modal-body {
  background-color: #dadfe0;
}

::v-deep .card {
  border-radius: 10px;
  box-shadow: 5px 5px 5px -3px rgb(0 0 0 / 15%);
}

::v-deep .modal-header {
  padding: 0;
}

.b-table-sticky-header {
  margin-bottom: 0;
}
</style>
