<template>
  <default-table-head>
    <v-col
      cols="1"
      class="d-flex justify-center"
    >
      <default-checkbox
        :indeterminate="isCheckboxIndeterminated"
        :disabled="isCheckboxDisabled"
        :value="areAllCheckboxesChecked"
        :title="areAllCheckboxesChecked ? 'Desmarcar Todos' : 'Marcar Todos'"
        @click="selectAll"
      ></default-checkbox>
    </v-col>
    <v-col
      class="d-block d-md-none ml-5 ml-sm-0"
      align="start"
    >
      <span
        v-if="checkedOrdersLength > 0"
        class="font-italic font-weight-light"
        ><span class="font-weight-bold mr-1">{{ checkedOrdersLength }}</span>
        <span v-if="checkedOrdersLength === 1">pedido selecionado</span
        ><span v-else>pedidos selecionados</span></span
      >
    </v-col>
    <v-col
      cols="3"
      class="border-r d-none d-md-block"
    >
      <span>Pedido</span>
    </v-col>
    <v-col
      cols="4"
      class="border-r d-none d-md-block"
    >
      <span>Cliente</span>
    </v-col>
    <v-col
      cols="3"
      class="d-none d-md-block border-r"
    >
      <span>Transportadora</span>
    </v-col>
    <v-col
      cols="1"
      class="d-none d-md-block"
    >
      <span>Status</span>
    </v-col>
  </default-table-head>
</template>

<script>
import DefaultCheckbox from "../../../shared/components/vuetify/DefaultCheckbox.vue";
import DefaultTableHead from "../../../shared/components/vuetify/DefaultTableHead.vue";
export default {
  name: "ListingHeaderManutencao",
  components: {
    DefaultCheckbox,
    DefaultTableHead,
  },
  props: {
    selectAll: {
      type: Function,
      required: true,
    },
    checkedOrdersLength: {
      type: Number,
      required: true,
    },
    shownOrdersLength: {
      type: Number,
      required: true,
    },
    blockedOrdersLength: {},
  },
  computed: {
    areAllCheckboxesChecked() {
      return (
        this.checkedOrdersLength + this.blockedOrdersLength ===
          this.shownOrdersLength && this.shownOrdersLength > 0
      );
    },
    isCheckboxDisabled() {
      return this.shownOrdersLength === 0;
    },
    isCheckboxIndeterminated() {
      return !this.areAllCheckboxesChecked && this.checkedOrdersLength > 0;
    },
  },
};
</script>

<style scoped></style>
